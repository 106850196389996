import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { controlProviderFor, ValueAccessor } from '@zmsac/common/core/utils/value-accessor';
import { ImageDeliveryType } from '@zmsac/common/core/enums/image-delivery-type';

/**
 * Component that represent radio input with text and tooltip info.
 */
@Component({
  selector: 'zmsac-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(RadioInputComponent)],
})
export class RadioInputComponent extends ValueAccessor<ImageDeliveryType> {

  /**
   * Label for radio.
   */
  @Input()
  public label = '';

  /**
   * Text of tooltip.
   */
  @Input()
  public tooltipText = '';

  /**
   * Active value.
   */
  @Input()
  public deliveryType: ImageDeliveryType = ImageDeliveryType.ProcessedImage;

  /**
   * Whether control is checked or not.
   */
  public get isChecked(): boolean {
    return this.controlValue === this.deliveryType;
  }
}
