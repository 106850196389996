import { FADE_COLOR, PADDING_SCALE } from '@zmsac/common/constants';
import { StrokeType } from '@zmsac/common/core/enums/stroke-type';
import { AppError } from '@zmsac/common/core/models/app-error';
import { RedactorConfiguration } from '@zmsac/common/core/models/redactor-configuration';
import { Observable, Subject } from 'rxjs';

import { createEllipseStoke } from './methods/create-ellipse-stoke';
import { createRectangleStroke } from './methods/create-rectangle-stroke';
import { createRoundedRectangleStroke } from './methods/create-rounded-rectangle-stroke';
import { createShapeStroke } from './methods/create-shape-stroke';
import { trimToContent } from './methods/trim-to-content';

/**
 * Add stroke to png image with transparent background.
 * @param imageUrl Image file.
 * @param processingOptions Processing options.
 */
export function strokeImage(
  imageUrl: string,
  processingOptions: RedactorConfiguration,
): Observable<string> {
  const img = new Image();

  const { color, thickness, strokeType } = processingOptions;

  /**
   * As image source we're using external source.
   * For that reason we should set this prop.
   * For more info https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image.
   */
  img.crossOrigin = 'Anonymous';
  const imgBase64$ = new Subject<string>();
  img.onload = () => {
    let canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

    // Draw an original image
    canvas.getContext('2d')?.drawImage(img, 0, 0);
    canvas = trimToContent(canvas);

    const contentPadding = Math.round(((canvas.width + canvas.height) / 2) * PADDING_SCALE);

    switch (strokeType) {
      case StrokeType.Stroke:
        canvas = createShapeStroke(
          canvas,
          contentPadding,
          color.toString(),
          thickness,
        );
        break;

      case StrokeType.Circle:
        canvas = createEllipseStoke(
          canvas,
          contentPadding,
          color.toString(),
          thickness,
          1,
        );
        break;

      case StrokeType.Ellipse:
        canvas = createEllipseStoke(
          canvas,
          contentPadding,
          color.toString(),
          thickness,
          (4 / 3),
        );
        break;

      case StrokeType.Rectangle:
        canvas = createRectangleStroke(
          canvas,
          contentPadding,
          color.toString(),
          thickness,
        );
        break;

      case StrokeType.RoundedRectangle:
        canvas = createRoundedRectangleStroke(
          canvas,
          contentPadding,
          color.toString(),
          thickness,
          FADE_COLOR,
        );
        break;
      default:
        throw new AppError(`${strokeType} stroke type is not supported yet.`);
    }
    imgBase64$.next(canvas.toDataURL('image/png'));
    canvas.remove();
  };
  img.src = imageUrl;
  return imgBase64$.asObservable();
}
