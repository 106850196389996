import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import '@saritasa/angular-typed-forms';
import { CommonSharedModule } from '@zmsac/common/shared/common-shared.module';

import { AppComponent } from './app.component';
import { ImageUploadModule } from './modules/image-upload/image-upload.module';

/** Base app module. */
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ImageUploadModule,
    CommonSharedModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
