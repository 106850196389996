import { Component, ElementRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { StrokeType } from '@zmsac/common/core/enums/stroke-type';
import { RedactorConfiguration } from '@zmsac/common/core/models/redactor-configuration';
import { OrdersService } from '@zmsac/common/core/services/orders.service';
import { toNumber } from '@zmsac/common/core/utils/to-number';
import { BehaviorSubject, Observable } from 'rxjs';

/** DOM attributes of sticker widget. */
enum WidgetAttribute {
  Type = 'stroketype',
  SizeBorderWidth = 'sizeborderwidth',
  SizeBorderHeight = 'sizeborderheight',
}

/** Base app component. */
@Component({
  selector: 'zmsaw-file-upload',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', '../theme/theme.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  providers: [OrdersService],
})
export class AppComponent implements OnDestroy {

  /** Provided redactor configuration from previous steps. */
  public readonly configuration$: Observable<RedactorConfiguration>;

  private readonly _configuration$ = new BehaviorSubject<RedactorConfiguration>(new RedactorConfiguration({}));

  /** Mutation observer. */
  private readonly observer: MutationObserver;

  public constructor(
    private readonly elementRef: ElementRef,
  ) {
    this.initOptions();
    this.configuration$ = this._configuration$.asObservable();
    this.observer = this.initObserver();
    this.observer.observe(this.elementRef.nativeElement, {
      attributes: true,
    });
  }

  /** @inheritDoc */
  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private initOptions(): void {
    const strokeType = this.elementRef.nativeElement.getAttribute(WidgetAttribute.Type);
    const sizeBorderWidth = this.elementRef.nativeElement.getAttribute(WidgetAttribute.SizeBorderWidth);
    const sizeBorderHeight = this.elementRef.nativeElement.getAttribute(WidgetAttribute.SizeBorderHeight);
    this._configuration$.next(this._configuration$.value.enrich({
      strokeType,
      sizeBorder: {
        width: toNumber(sizeBorderWidth),
        height: toNumber(sizeBorderHeight),
      },
    }));
  }

  private initObserver(): MutationObserver {
    return new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => this.handleMutation(mutation));
    });
  }

  private handleMutation(mutation: MutationRecord): void {
    if (mutation.type !== 'attributes') {
      return;
    }
    if (mutation.attributeName === WidgetAttribute.Type) {
      this.changeStrokeType(mutation);
    } else if (mutation.attributeName === WidgetAttribute.SizeBorderWidth ||
      mutation.attributeName === WidgetAttribute.SizeBorderHeight) {
      this.changeSizeBorder(mutation);
    }
  }

  private changeStrokeType(mutation: MutationRecord): void {
    const strokeType = ((mutation.target as Element).getAttribute(WidgetAttribute.Type) ?? StrokeType.Stroke) as StrokeType;
    this._configuration$.next(this._configuration$.value.enrich({ strokeType }));
  }

  private changeSizeBorder(mutation: MutationRecord): void {
    const sizeBorderHeight = (mutation.target as Element).getAttribute(WidgetAttribute.SizeBorderHeight);
    const sizeBorderWidth = (mutation.target as Element).getAttribute(WidgetAttribute.SizeBorderWidth);
    const sizeBorder = {
      width: toNumber(sizeBorderWidth),
      height: toNumber(sizeBorderHeight),
    };
    this._configuration$.next(this._configuration$.value.enrich({ sizeBorder }));
  }
}
