<div class="input-container">
  <button (click)="hiddenInput.click()" type="button">Choose...</button>
  <ng-container>
    <label *ngIf="fileName$ | async as fileName; else defaultPlaceholder" class="placeholder">{{ fileName }}</label>
  </ng-container>
</div>
<input
  type="file"
  class="hidden-input"
  tabindex="-1"
  (change)="onFileUpload($event)"
  [(ngModel)]="controlValue"
  #hiddenInput
/>

<ng-template #defaultPlaceholder>
  <label class="placeholder">No file chosen</label>
</ng-template>
