/**
 * Returns canvas with rectangle bush.
 * @param height Brush height.
 * @param width Brush width.
 * @param color Brush color.
 */
export function generateRectangleBrush(
  height: number,
  width: number,
  color: string,
): HTMLCanvasElement {
  const brush = document.createElement('canvas');
  brush.width = width;
  brush.height = height;

  const brushCtx = brush.getContext('2d') as CanvasRenderingContext2D;
  brushCtx.fillStyle = color;
  brushCtx.fillRect(0, 0, width, height);

  return brush;
}
