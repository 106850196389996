import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';

import { assertNonNull } from './assert-non-null';

/**
 * Injection token that used to get a window object.
 */
export const WINDOW = new InjectionToken('window', {
  factory() {
    const { defaultView } = inject(DOCUMENT);
    assertNonNull(defaultView);
    return defaultView;
  },
});
