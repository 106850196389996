/**
 * Returns canvas with round smooth bush. Use radial gradient. Second color must be transparent.
 * @param radius Radius of a brush.
 * @param smoothness Float value from 0 to 1. Indicates when on radius color starts to fade.
 * @param mainColor Main color.
 * @param fadeColor Fade color. To smooth brush must be with 0 in alpha channel.
 */
export function generateSmoothBrush(
  radius: number,
  smoothness: number,
  mainColor: string,
  fadeColor: string,
): HTMLCanvasElement {
  if (!(smoothness >= 0 && smoothness <= 1)) {
    throw (new Error('Wrong brush smoothness value'));
  }

  const brush = document.createElement('canvas');
  brush.width = radius * 2;
  brush.height = radius * 2;

  const brushCtx = brush.getContext('2d') as CanvasRenderingContext2D;
  brushCtx.beginPath();

  const gradient = brushCtx.createRadialGradient(
    radius,
    radius,
    radius * smoothness,
    radius,
    radius,
    radius,
  );
  gradient.addColorStop(0, mainColor);
  gradient.addColorStop(1, fadeColor);

  brushCtx.fillStyle = gradient;
  brushCtx.arc(radius, radius, radius, 0, Math.PI * 2);
  brushCtx.fill();
  brushCtx.closePath();

  return brush;
}
