import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CheckboxControlValueAccessor, FormControl } from '@angular/forms';
import { controlProviderFor } from '@zmsac/common/core/utils/value-accessor';

/**
 * Switch input component.
 */
@Component({
  selector: 'zmsac-switch-input',
  templateUrl: './switch-input.component.html',
  styleUrls: ['./switch-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(SwitchInputComponent)],
})
export class SwitchInputComponent extends CheckboxControlValueAccessor {

  /** Control for switch input. */
  public readonly switchControl = new FormControl();

  /** @inheritDoc */
  public override writeValue(value: any): void {
    super.writeValue(value);
    this.switchControl.setValue(value);
  }
}
