import { SizeBorder } from '@zmsac/common/core/models/redactor-configuration';
import { fabric } from 'fabric';

import { trimToContent } from './trim-to-content';

const IMAGE_SCALE_MULTIPLIER = 0.8;
const BOX_SCALE_MULTIPLIER = 0.9;

interface BoxSize {

  /** Height of the box in pixels. */
  readonly height: number;

  /** Width of the box in pixels. */
  readonly width: number;
}

/**
 * Draw size border to see if the image fit to it.
 * @param originalImage Original image without size border.
 * @param border Size border.
 */
export function drawSizeBorder(
  originalImage: fabric.Image,
  border: SizeBorder,
): fabric.Image {

  const originalCanvas = originalImage.toCanvasElement();

  const imageBiggestSide = originalCanvas.height < originalCanvas.width ? originalCanvas.width : originalCanvas.height;

  const boxSize = calculateBoxSize(originalCanvas, border);

  const strokeSize = imageBiggestSide * 0.01;

  const box: fabric.Rect = new fabric.Rect({
    originX: 'center',
    originY: 'center',
    height: boxSize.height,
    width: boxSize.width,
    scaleY: BOX_SCALE_MULTIPLIER,
    scaleX: BOX_SCALE_MULTIPLIER,
    stroke: '#c5abd1',
    fill: 'transparent',
    strokeWidth: strokeSize,
  });

  const image = createImage(border, boxSize, originalCanvas);

  const fontSize = imageBiggestSide * 0.05;

  const widthLeft = ((imageBiggestSide / 2) - ((boxSize.height * BOX_SCALE_MULTIPLIER) / 2)) - (fontSize / 2) - (strokeSize / 2);

  const widthText = new fabric.Text(`${border.width}”`, {
    originX: 'center',
    originY: 'center',
    top: widthLeft,
    fontSize,
    textAlign: 'center',
    fill: 'white',
  });

  const heightTop = ((imageBiggestSide / 2) - ((boxSize.width * BOX_SCALE_MULTIPLIER) / 2)) - (fontSize / 2) - (strokeSize / 2);

  const heightText = new fabric.Text(`${border.height}”`, {
    originX: 'center',
    originY: 'center',
    left: heightTop,
    fontSize,
    textAlign: 'center',
    fill: 'white',
    angle: 270,
  });

  const canvas = new fabric.Canvas(document.createElement('canvas'), {
    height: imageBiggestSide,
    width: imageBiggestSide,
  });

  const sizeBoxGroup = new fabric.Group([box, image], {
    originX: 'center',
    originY: 'center',
    height: imageBiggestSide,
    width: imageBiggestSide,
  });

  canvas.add(sizeBoxGroup);
  canvas.centerObject(sizeBoxGroup);

  canvas.add(widthText);
  canvas.centerObjectH(widthText);

  canvas.add(heightText);
  canvas.centerObjectV(heightText);

  const trimmedCanvas = trimToContent(canvas.toCanvasElement());

  return new fabric.Image(trimmedCanvas, {
    selectable: false,
    hasControls: false,
    hoverCursor: 'default',
    moveCursor: 'default',
  });
}

/**
 * Calculate box size for provided canvas.
 * @param canvas Original canvas.
 * @param sizeBorder Size border.
 */
function calculateBoxSize(canvas: HTMLCanvasElement, sizeBorder: SizeBorder): BoxSize {
  type DimensionType = 'height' | 'width';

  const measurementUnitType: DimensionType = sizeBorder.width > sizeBorder.height ? 'width' : 'height';
  const typeOfBiggestSide: DimensionType = canvas.height < canvas.width ? 'width' : 'height';

  let boxWidth = 0;
  let boxHeight = 0;

  if (typeOfBiggestSide === 'width' && measurementUnitType === 'width') {
    boxWidth = canvas.width;
    boxHeight = (canvas.width / sizeBorder.width) * sizeBorder.height;
  } else if (typeOfBiggestSide === 'height' && measurementUnitType === 'height') {
    boxHeight = canvas.height;
    boxWidth = (canvas.height / sizeBorder.height) * sizeBorder.width;
  } else if (typeOfBiggestSide === 'width' && measurementUnitType === 'height') {
    boxHeight = canvas.width;
    boxWidth = (canvas.width / sizeBorder.height) * sizeBorder.width;
  } else if (typeOfBiggestSide === 'height' && measurementUnitType === 'width') {
    boxWidth = canvas.height;
    boxHeight = (canvas.height / sizeBorder.width) * sizeBorder.height;
  }

  return {
    width: boxWidth,
    height: boxHeight,
  };
}

/**
 * Function that create an image.
 * @param sizeBorder Size border.
 * @param boxSize Box size.
 * @param canvas Original canvas.
 */
function createImage(sizeBorder: SizeBorder, boxSize: BoxSize, canvas: HTMLCanvasElement): fabric.Image {

  const image = new fabric.Image(canvas, {
    originX: 'center',
    originY: 'center',
  });

  scaleImage(image, sizeBorder, boxSize, canvas);

  return image;
}

/**
 * Scale provided image.
 * @param image Image to scale.
 * @param sizeBorder Size border.
 * @param boxSize Box size.
 * @param canvas Original canvas.
 * TODO: (Romanov) Improve scaling logic so it won't be so confusing.
 */
function scaleImage(image: fabric.Image, sizeBorder: SizeBorder, boxSize: BoxSize, canvas: HTMLCanvasElement): void {
  if (sizeBorder.width === sizeBorder.height) {
    if (canvas.height > canvas.width) {
      image.scaleToWidth(canvas.width * IMAGE_SCALE_MULTIPLIER);
    } else {
      image.scaleToHeight(canvas.height * IMAGE_SCALE_MULTIPLIER);
    }
  } else if (sizeBorder.width > sizeBorder.height && boxSize.height > boxSize.width) {
    image.scaleToHeight(boxSize.height * IMAGE_SCALE_MULTIPLIER);
  } else if (sizeBorder.width < sizeBorder.height && boxSize.height < boxSize.width) {
    image.scaleToWidth(boxSize.width * IMAGE_SCALE_MULTIPLIER);
  } else if (sizeBorder.width > sizeBorder.height && boxSize.height < boxSize.width) {

    /** In case is too wide there is no need to scale the height f.e. 3000*300. */
    if (canvas.height > boxSize.height) {
      image.scaleToHeight(boxSize.height * IMAGE_SCALE_MULTIPLIER);
    } else {
      image.scaleToWidth(boxSize.width * IMAGE_SCALE_MULTIPLIER);
    }
  } else if (sizeBorder.width < sizeBorder.height && boxSize.height > boxSize.width) {

    /** In case image is too high there is no need to scale the width f.e. 500*6000. */
    if (canvas.width > boxSize.width) {
      image.scaleToWidth(boxSize.width * IMAGE_SCALE_MULTIPLIER);
    } else {
      image.scaleToHeight(boxSize.height * IMAGE_SCALE_MULTIPLIER);
    }
  }
}
