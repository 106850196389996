import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Self } from '@angular/core';
import { FormBuilder, FormGroupTyped, ValidatorFn, Validators } from '@angular/forms';
import { ImageDeliveryType } from '@zmsac/common/core/enums/image-delivery-type';
import { StrokeType } from '@zmsac/common/core/enums/stroke-type';
import { AppError } from '@zmsac/common/core/models/app-error';
import { Mime } from '@zmsac/common/core/models/mime';
import { Order } from '@zmsac/common/core/models/order';
import { ProcessingProgress } from '@zmsac/common/core/models/processing-progress';
import { RedactorConfiguration } from '@zmsac/common/core/models/redactor-configuration';
import { filterNull } from '@zmsac/common/core/rxjs/filter-null';
import { listenControlChanges } from '@zmsac/common/core/rxjs/listen-control-changes';
import { DestroyService } from '@zmsac/common/core/services/destroy.service';
import { ImageProcessingService } from '@zmsac/common/core/services/image-processing.service';
import { OrdersService } from '@zmsac/common/core/services/orders.service';
import { ProcessingProgressService } from '@zmsac/common/core/services/processing-progress.service';
import { enumToArray } from '@zmsac/common/core/utils/enum-to-array';
import { FileValidation } from '@zmsac/common/core/utils/file-validation';
import { WINDOW } from '@zmsac/common/core/utils/injection-tokens';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  mapTo,
  merge,
  Observable,
  takeUntil,
  timer,
} from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';

interface ImageUploadForm extends Omit<Order, 'originalImage'> {

  /**
   * Original image file.
   */
  readonly originalImage: File | null;

  /**
   * Whether image background should be removed or not.
   */
  readonly shouldRemoveBackground: boolean;
}

export namespace ImageValidation {

  export const IMAGE_VALIDATORS: ValidatorFn[] = [
    Validators.required,
    FileValidation.validateImageMime(enumToArray(Mime.SupportedType)),
  ];

  export const REORDER_VALIDATORS: ValidatorFn[] = [
    Validators.required,
    Validators.maxLength(100),
    Validators.pattern('^[1-9][0-9]*$'),
  ];
}

/**
 * Stage of image uploading.
 */
enum UploadStage {

  /**
   * Stage on which user select image or document that he want to use for uploading / processing.
   */
  Select = 'Select',

  /**
   * Stage on which user can preview result of image processing.
   */
  Preview = 'Preview',

  /**
   * Stage where user select processing configurations like size, shape etc.
   */
  Menu = 'Menu',
}

const ERROR_MESSAGE = 'Something went wrong. Please try again.';
const MESSAGE_DURATION_MS = 2000;

/** Image uploading component. Collects and send data about upload.  */
@Component({
  selector: 'zmsaw-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ImageUploadComponent implements OnInit {

  /** Processing options. */
  @Input()
  public set processingOptions(options: RedactorConfiguration | null) {
    if (options !== null) {
      this.processingService.changeProcessingOptions(options);
    }
  }

  /** Represent cards that available for now. */
  public readonly availableCards: readonly ImageDeliveryType[] = [
    ImageDeliveryType.ProcessedImage,
    ImageDeliveryType.Email,
    ImageDeliveryType.Reorder,
  ];

  /** Template form. */
  public readonly form: FormGroupTyped<ImageUploadForm>;

  /** Whether user can submit form or not. */
  public readonly isSubmitUnavailable$: Observable<boolean>;

  /** Processed image in base64 format. */
  public readonly processedImage$ = new BehaviorSubject<string | null>(null);

  /** Current stage of uploading. */
  public readonly uploadingStage$ = new BehaviorSubject<UploadStage>(UploadStage.Select);

  /** Upload stage. */
  public readonly UploadStage = UploadStage;

  /** Whether image is loading or not. */
  public readonly processingProgress$: Observable<ProcessingProgress | null>;

  /** Progress of processing. */
  public readonly ProcessingProgress = ProcessingProgress;

  /** Return human-readable representation of the progress. */
  public readonly toReadableProgress = ProcessingProgress.toReadable;

  /** Image preview animation class. */
  public animPreview = 'hidden';

  /** Image fields animation class. */
  public animFields = '';

  /** Whether image has background invariants or not. */
  public readonly hasBackgroundInvariants$: Observable<boolean>;

  /** Current delivery type. */
  public readonly currentDeliveryType$: Observable<ImageDeliveryType>;

  /** Image delivery type options. */
  public readonly ImageDeliveryType = ImageDeliveryType;

  /** Error message. */
  public readonly errorMessage$: Observable<string | null>;

  private readonly _errorMessage$ = new BehaviorSubject<string | null>(null);

  public constructor(
    @Self() @Inject(DestroyService) private readonly destroy$: Observable<void>,
    public readonly processingService: ImageProcessingService,
    private readonly ordersService: OrdersService,
    private readonly fb: FormBuilder,
    private readonly processingProgressService: ProcessingProgressService,
    @Inject(WINDOW) private readonly window: Window,
  ) {
    this.form = this.initializeForm();
    this.isSubmitUnavailable$ = this.initializeSubmitUnavailable();
    this.processingProgress$ = this.processingProgressService.processingProgress$;
    this.hasBackgroundInvariants$ = this.initializeBackgroundInvariants();
    this.currentDeliveryType$ = listenControlChanges(this.form.controls.imageDeliveryType);
    this.errorMessage$ = this._errorMessage$.asObservable();
  }

  /** @inheritDoc */
  public ngOnInit(): void {
    merge(
      this.setImageSideEffect(),
      this.validateFormSideEffect(),
      this.processImageSideEffect(),
      this.onDeliveryTypeChangeSideEffect(),
      this.applyAnimationsSideEffect(),
      this.backToMenuSideEffect(),
      this.resetErrorMessageSideEffect(),
    ).pipe(
      takeUntil(this.destroy$),
    )
      .subscribe();
  }

  /** Handle form submit. */
  public onSubmit(): void {
    this.processedImage$.pipe(
      first(),
      tap(image => {

        /** In order to omit 'shouldRemoveBackground' option. */
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { shouldRemoveBackground, ...order } = this.form.value;
        this.ordersService.placeOrder(order, image ?? null);
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  /** Perform actions on back button click. */
  public onBackButtonClick(): void {
    if (this.uploadingStage$.value === UploadStage.Preview) {
      this.uploadingStage$.next(UploadStage.Select);
    } else {
      this.uploadingStage$.next(UploadStage.Menu);
    }
  }

  private resetErrorMessageSideEffect(): Observable<void> {
    return this._errorMessage$.pipe(
      filterNull(),
      switchMap(() => timer(MESSAGE_DURATION_MS)),
      tap(() => this._errorMessage$.next(null)),
      mapTo(void 0),
    );
  }

  private setImageSideEffect(): Observable<void> {
    return this.processingService.imageInBase64Format$.pipe(
      tap(image => {
        this.processedImage$.next(image);

        if (image == null) {
          this.processingProgressService.resetTheProgress();
          this.form.controls.originalImage.setValue(null);
          this._errorMessage$.next(ERROR_MESSAGE);
        }

        if (this.uploadingStage$.value !== UploadStage.Preview) {
          this.uploadingStage$.next(UploadStage.Preview);
          this.window.scrollTo({ top: 0 });
        }
      }),
      mapTo(void 0),
    );
  }

  private validateFormSideEffect(): Observable<void> {
    const imageInput = this.form.controls.originalImage;
    const reorderNumberInput = this.form.controls.reorderNumber;

    return this.form.controls.imageDeliveryType.valueChanges.pipe(
      tap(deliveryType => {
        switch (deliveryType) {
          case ImageDeliveryType.ProcessedImage:
            reorderNumberInput.clearValidators();
            imageInput.setValidators(ImageValidation.IMAGE_VALIDATORS);
            break;
          case ImageDeliveryType.PlainFile:
            reorderNumberInput.clearValidators();
            imageInput.setValidators(Validators.required);
            break;
          case ImageDeliveryType.Email:
            imageInput.clearValidators();
            reorderNumberInput.clearValidators();
            break;
          case ImageDeliveryType.Reorder:
            imageInput.clearValidators();
            reorderNumberInput.setValidators(ImageValidation.REORDER_VALIDATORS);
            break;
          default:
            throw new AppError(`Image of type ${deliveryType} is not supported yet.`);
        }
        imageInput.updateValueAndValidity();
        reorderNumberInput.updateValueAndValidity();
      }),
      mapTo(void 0),
    );
  }

  private processImageSideEffect(): Observable<void> {
    return this.form.controls.originalImage.valueChanges.pipe(
      debounceTime(300),
      filterNull(),
      filter(() => !this.form.controls.originalImage.invalid &&
        this.form.controls.imageDeliveryType.value === ImageDeliveryType.ProcessedImage),
      tap(file => this.processingService.setCurrentImage(file, this.form.controls.shouldRemoveBackground.value)),
      mapTo(void 0),
    );
  }

  private onDeliveryTypeChangeSideEffect(): Observable<void> {
    return this.form.controls.imageDeliveryType.valueChanges.pipe(
      tap(() => {
        this.form.controls.originalImage.setValue(null);
        this.form.controls.reorderNumber.setValue(null);
      }),
      mapTo(void 0),
    );
  }

  private applyAnimationsSideEffect(): Observable<void> {
    return this.uploadingStage$.pipe(
      tap(stage => {
        if (stage === UploadStage.Preview) {
          this.animPreview = 'anim-slide-in';
          this.animFields = 'hidden';
        } else {
          this.animPreview = 'hidden';
          this.animFields = 'anim-slide-in';
        }
      }),
      mapTo(void 0),
    );
  }

  private backToMenuSideEffect(): Observable<void> {
    return this.uploadingStage$.pipe(
      tap(stage => {
        if (stage === UploadStage.Select) {
          this.processingService.resetPartOfOptions();
          this.form.controls.originalImage.setValue(null);
          this.processedImage$.next(null);
        } else if (stage === UploadStage.Menu) {
          this.processingService.resetAllOptions();
          this.ordersService.backToMenu();
          this.form.controls.shouldRemoveBackground.setValue(true);
        }
      }),
      mapTo(void 0),
    );
  }

  private initializeForm(): FormGroupTyped<ImageUploadForm> {
    const form = this.fb.groupTyped<ImageUploadForm>({
      imageDeliveryType: [ImageDeliveryType.ProcessedImage],
      reorderNumber: [null],
      specialRequest: [null],
      originalImage: [null, ImageValidation.IMAGE_VALIDATORS],
      shouldRemoveBackground: [true],
    });

    /** By making field dirty we restrict to the user 'add to cart' actions at the start on purpose. */
    form.controls.originalImage.markAsDirty();
    return form;
  }

  private initializeSubmitUnavailable(): Observable<boolean> {
    const isFormInvalid$ = this.form.valueChanges.pipe(
      map(() => this.form.invalid),
      startWith(true),
    );

    return combineLatest([isFormInvalid$, this.processingProgress$]).pipe(
      map(([isFormValid, progress]) => isFormValid || progress === ProcessingProgress.Completed),
    );
  }

  private initializeBackgroundInvariants(): Observable<boolean> {
    return this.processingService.redactorOptions$.pipe(
      map(({ strokeType }) => strokeType !== StrokeType.Stroke),
    );
  }
}
