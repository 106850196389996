<ng-container>
  <div class="container">
    <ng-container *ngIf="processingProgress$ | async as progress">
      <zmsac-spinner *ngIf="progress !== ProcessingProgress.Completed"></zmsac-spinner>
    </ng-container>
    <section
	    [style.background-image]="'url(' + backgroundPath + ')'"
	    class="card image-preview" *ngIf="imageUrl$ | async as url"
	    #canvasSection
    >
	    <canvas class="canvas" #canvas></canvas>
    </section>
    <section class="image-editor">
      <p class="editor-title">SELECT BORDER THICKNESS & COLOR</p>
      <div class="controls">
        <div class="thickness">
          <img src="{{appConfigService.bucketUrl}}/assets/thickness.svg" alt="Thickness">
          <img
            class="thickness-variant"
            (click)="onBackgroundThicknessChange(ThicknessType.Small)"
            src="{{appConfigService.bucketUrl}}/assets/small-thickness.svg"
            alt="Small"
          >
          <img
            class="thickness-variant"
            (click)="onBackgroundThicknessChange(ThicknessType.Medium)"
            src="{{appConfigService.bucketUrl}}/assets/medium-thickness.svg"
            alt="Medium"
          >
          <img
            class="thickness-variant"
            (click)="onBackgroundThicknessChange(ThicknessType.Large)"
            src="{{appConfigService.bucketUrl}}/assets/large-thickness.svg"
            alt="Large"
          >
        </div>
        <div class="color">
          <img src="{{appConfigService.bucketUrl}}/assets/color-picker.svg" alt="Color">
          <input
            [formControl]="colorControl"
            (change)="onBackgroundColorChange($event)"
            value="color"
            type="color"
          >
        </div>
      </div>
    </section>
  </div>
</ng-container>
