/**
 * Type of image delivery.
 */
export enum ImageDeliveryType {

  /**
   * With image that were processed.
   */
  ProcessedImage = 'ProcessedImage',

  /**
   * Just plain file that contain the image without any processing.
   */
  PlainFile = 'PlainFile',

  /**
   * With email.
   */
  Email = 'Email',

  /**
   * It is not the first time user placing the order, and he wants to repeat a previous one.
   */
  Reorder = 'Reorder',
}
