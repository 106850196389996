/**
 * Map given value to a number. In case value was incorrect or there is no value return 0.
 * @param value Value to parse.
 */
export function toNumber(value?: string | null | number): number {
  if (value == null) {
    return 0;
  }
  const parsedValue = Number(value);
  if (Number.isNaN(parsedValue)) {
    return 0;
  }
  return parsedValue;
}
