import { ValidationErrors } from '@angular/forms';
import { ValidationErrorCode } from '@zmsac/common/core/models/validation-error-code';

export namespace ZMSAValidators {

  /**
   * Create custom validation error message.
   * @param message Message to show.
   */
  export function buildAppError(message: string): ValidationErrors {
    return {
      [ValidationErrorCode.AppError]: {
        message,
      },
    };
  }
}
