import { ThicknessType } from '@zmsac/common/core/enums/thickness';
import { fabric } from 'fabric';

/**
 * Creates rectangle stroke around image. Returns new canvas.
 * @param originalCanvas Original image canvas.
 * @param contentPadding Padding from border.
 * @param color Stroke color.
 * @param thickness Current thickness value.
 */
export function createRectangleStroke(
  originalCanvas: HTMLCanvasElement,
  contentPadding: number,
  color: string,
  thickness: ThicknessType,
): HTMLCanvasElement {

  const finalWidth = (originalCanvas.width) + (thickness * (contentPadding * 2));
  const finalHeight = (originalCanvas.height) + (thickness * (contentPadding * 2));

  const rect = new fabric.Rect({
    originX: 'center',
    originY: 'center',
    fill: color,
    width: finalWidth,
    height: finalHeight,
  });

  const image = new fabric.Image(originalCanvas, {
    originX: 'center',
    originY: 'center',
  });

  const canvas = new fabric.Canvas(null, {
    height: finalHeight,
    width: finalWidth,
  });

  canvas.add(rect);
  canvas.centerObject(rect);

  canvas.add(image);
  canvas.centerObject(image);

  return canvas.toCanvasElement();
}
