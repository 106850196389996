import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, Input, Self, ViewChild } from '@angular/core';
import { DestroyService } from '@zmsac/common/core/services/destroy.service';
import { fromEvent, Observable, takeUntil, tap } from 'rxjs';

/**
 * Pop up message component.
 */
@Component({
  selector: 'zmsac-pop-up-message',
  templateUrl: './pop-up-message.component.html',
  styleUrls: ['./pop-up-message.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class PopUpMessageComponent implements AfterViewInit {

  /** Error message. */
  @Input()
  public message: string | null = null;

  /** Reference to overlay element. */
  @ViewChild('overlay')
  public overlay!: ElementRef;

  public constructor(
    @Self() @Inject(DestroyService) private readonly destroy$: Observable<void>,
  ) {}

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    const closeOverlaySideEffect$ = fromEvent(this.overlay.nativeElement, 'click').pipe(
      tap(() => this.overlay.nativeElement.remove()),
    );

    closeOverlaySideEffect$.pipe(takeUntil(this.destroy$)).subscribe();
  }
}
