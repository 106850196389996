import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/**
 * Spinner component. Requires 'position: relative' CSS attribute in holding element.
 */
@Component({
  selector: 'zmsac-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {

  /** Message that will be displayed below the spinner. */
  @Input()
  public message: string | null = null;
}
