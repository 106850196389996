import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';

import { CustomFileInputComponent } from './custom-file-input/custom-file-input.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { RadioCardComponent } from './radio-card/radio-card.component';

const routes: Routes = [{ path: '', pathMatch: 'full', component: ImageUploadComponent }];

/**
 * Dashboard module.
 */
@NgModule({
  declarations: [
    ImageUploadComponent,
    RadioCardComponent,
    CustomFileInputComponent,
    ImagePreviewComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
  ],
  exports: [ImageUploadComponent],
})
export class ImageUploadModule { }
