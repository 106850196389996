<label class="radio">
  <span class="radio-input">
    <input
	    [checked]="isChecked"
      [(ngModel)]="controlValue"
      [value]="deliveryType"
      type="radio"
    >
    <span class="radio-control"></span>
  </span>
  <div class="radio-content">
    <span *ngIf="label" class="radio-label">{{ label }}</span>
    <div *ngIf="tooltipText" class="tooltip">
      <span class="tooltip-text">{{ tooltipText }}</span>
      <span class="icon info"></span>
    </div>
  </div>
</label>
