import { PIXEL_STEP } from '@zmsac/common/constants';
import { ThicknessType } from '@zmsac/common/core/enums/thickness';

import { generateSmoothBrush } from './generate-smooth-brush';
import { generateStroke } from './generate-stroke';

/**
 * Creates rounded rectangle stroke around image. Returns new canvas.
 * @param canvas Original image canvas.
 * @param contentPadding Padding to content.
 * @param color Stroke color.
 * @param thickness Current thickness value.
 * @param fadeColor Stroke edges fade.
 */
export function createRoundedRectangleStroke(
  canvas: HTMLCanvasElement,
  contentPadding: number,
  color: string,
  thickness: ThicknessType,
  fadeColor: string,
): HTMLCanvasElement {
  // Create double padded canvas for generating starting rectangle.
  const roundedRectCanvas = document.createElement('canvas');
  roundedRectCanvas.width = canvas.width + (contentPadding * PIXEL_STEP) * thickness;
  roundedRectCanvas.height = canvas.height + (contentPadding * PIXEL_STEP) * thickness;

  // Drawing starting rectangle.
  const rsqrCtx = roundedRectCanvas.getContext('2d') as CanvasRenderingContext2D;
  rsqrCtx.fillStyle = color;
  rsqrCtx.fillRect(
    contentPadding * 2,
    contentPadding * 2,
    roundedRectCanvas.width - contentPadding * PIXEL_STEP,
    roundedRectCanvas.height - contentPadding * PIXEL_STEP,
  );

  rsqrCtx.drawImage(generateStroke(
    rsqrCtx,
    generateSmoothBrush(
      contentPadding,
      0.7,
      color,
      fadeColor,
    ),
    contentPadding,
  ),
  0,
  0);
  rsqrCtx.drawImage(
    canvas,
    contentPadding * 2 * thickness,
    contentPadding * 2 * thickness,
  );

  return roundedRectCanvas;
}
