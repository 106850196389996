import { Injectable } from '@angular/core';
import { ProcessedImageDto } from '@zmsac/common/core/mappers/dto/processed-image-dto';
import { ProcessImageMapper } from '@zmsac/common/core/mappers/process-image.mapper';
import { ProcessedImage } from '@zmsac/common/core/models/processed-image';
import { AppConfigService } from '@zmsac/common/core/services/app-config.service';
import { defer, Observable, tap } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FilesService } from '@zmsac/common/core/services/files.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ProcessingProgress } from '../models/processing-progress';

import { ProcessingProgressService } from './processing-progress.service';

/** Format of supported uploading file. */
enum UploadingFormat {

  /** PDF format. */
  PDF = 'pdf',
}

/** Bucket that will store image. */
interface BucketStorage {

  /** Object key. */
  readonly object_key: string;

  /** Presigned url for image. */
  readonly presigned_url: string;
}

/**
 * Service that communicate with API that work with image.
 */
@Injectable({
  providedIn: 'root',
})
export class ImageApiService {

  private readonly url: URL;

  public constructor(
    private readonly filesService: FilesService,
    private readonly processImageMapper: ProcessImageMapper,
    private readonly appConfigService: AppConfigService,
    private readonly httpClient: HttpClient,
    private readonly processingProgressService: ProcessingProgressService,
  ) {
    this.url = new URL('', this.appConfigService.processingUrl);
  }

  /**
   * Sends image to background-removal script. Receives image in base64 string.
   * @param file File that contain an image.
   */
  public removeImageBackground(file: File): Observable<ProcessedImage> {
    return defer(() => this.filesService.fileToArrayBuffer(file)).pipe(
      map(buffer => new Blob([new Uint8Array(buffer)], { type: 'image/jpeg' })),
      switchMap(blob => this.removeBackground(blob, this.url.toString())),
    );
  }

  /**
   * Remove background of pdf.
   * @param file PDF file for processing.
   */
  public removePdfBackground(file: File): Observable<ProcessedImage> {
    const url = new URL('', this.url);
    url.searchParams.append('format', UploadingFormat.PDF);
    return this.removeBackground(file, url.toString());
  }

  private removeBackground(source: Blob, url: string): Observable<ProcessedImage> {
    return this.getBucketStorage(url).pipe(
      switchMap(bucketStorage => this.uploadImageToBucket(source, bucketStorage.presigned_url).pipe(
          switchMap(() => this.getPresignedUrl(bucketStorage.object_key)),
      )),
      map(result => this.processImageMapper.fromDto(result)),
    );
  }

  // Fetch used to avoid issues with XHR sync requests deprecation.
  private getBucketStorage(url: string): Observable<BucketStorage> {
    const headers = new HttpHeaders({
      'Content-Type': '',
    });
    return this.httpClient.get<BucketStorage>(url, { headers });
  }

  private uploadImageToBucket(image: Blob, presignedUrl: string): Observable<void> {
    const headers = new HttpHeaders({
      'Content-Type': '',
    });
    return this.httpClient.put<void>(presignedUrl, image, { headers }).pipe(
      tap(() => this.processingProgressService.updateProgress(ProcessingProgress.Cutting)),
    );
  }

  private getPresignedUrl(storageKey: string): Observable<ProcessedImageDto> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = JSON.stringify({ object_key: storageKey });
    return this.httpClient.post<ProcessedImageDto>(this.appConfigService.processingUrl, body, {
      headers,
    });
  }
}
