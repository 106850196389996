<div class="onoffswitch">
  <input
    [formControl]="switchControl"
    type="checkbox"
    class="onoffswitch-checkbox"
    id="switch-input"
  >
  <label class="onoffswitch-label" for="switch-input">
    <span class="onoffswitch-inner onoffclass"></span>
    <span class="onoffswitch-switch onoffclass"></span>
  </label>
</div>