<div class="container">
  <zmsac-pop-up-message
    *ngIf="errorMessage$ | async as message"
    [message]="message"
  ></zmsac-pop-up-message>
  <h1 class="title">Upload your artwork</h1>
  <p *ngIf="(uploadingStage$ | async) === UploadStage.Preview" class="disclaimer">
    For cutline preview only, print quality may vary depending on your upload quality
  </p>
  <form class="form-group" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="field spinner-holder" [ngClass]="animFields">
      <ng-container *ngIf="processingProgress$ | async as progress">
        <zmsac-spinner
          *ngIf="progress !== ProcessingProgress.Completed"
          [message]="toReadableProgress(progress)"
        >
        </zmsac-spinner>
      </ng-container>

      <!-- Upload card content -->
      <zmsaw-radio-card class="upload-controls">
        <zmsac-radio-input
          [deliveryType]="ImageDeliveryType.ProcessedImage"
          formControlName="imageDeliveryType"
          label="Upload jpeg/png or pdf for instant proof preview"
          tooltipText="More file formats coming soon!"
        >
        </zmsac-radio-input>

        <div *ngIf="(currentDeliveryType$ | async) === ImageDeliveryType.ProcessedImage" class="file-input">
          <div *ngIf="hasBackgroundInvariants$ | async" class="background-settings">
            <span>Remove background</span>
            <zmsac-switch-input formControlName="shouldRemoveBackground">
            </zmsac-switch-input>
          </div>
          <zmsaw-custom-file-input formControlName="originalImage">
          </zmsaw-custom-file-input>
          <zmsac-form-control-validation-message class="validation-message" controlName="originalImage">
          </zmsac-form-control-validation-message>
        </div>

        <zmsac-radio-input
          [deliveryType]="ImageDeliveryType.PlainFile"
          formControlName="imageDeliveryType"
          label="Upload other files"
          tooltipText="It will be uploaded without preview"
        ></zmsac-radio-input>
        <div *ngIf="(currentDeliveryType$ | async) === ImageDeliveryType.PlainFile" class="file-input">
          <zmsaw-custom-file-input formControlName="originalImage">
          </zmsaw-custom-file-input>
          <zmsac-form-control-validation-message class="validation-message" controlName="originalImage">
          </zmsac-form-control-validation-message>
        </div>

      </zmsaw-radio-card>

      <!-- Email card content -->
      <zmsaw-radio-card>
        <div class="upload-controls">
          <zmsac-radio-input
            [deliveryType]="ImageDeliveryType.Email"
            formControlName="imageDeliveryType"
            label="Email file later"
            tooltipText="Email your artwork to graphics@zugmonster.com"
          >
          </zmsac-radio-input>
        </div>
      </zmsaw-radio-card>

      <!-- Reorder card content -->
      <zmsaw-radio-card>
        <div class="upload-controls">
          <zmsac-radio-input
            [deliveryType]="ImageDeliveryType.Reorder"
            formControlName="imageDeliveryType"
            label="This is a reorder"
            tooltipText="A reorder means that you've already printed this design, and you'd like us print it again"
          >
          </zmsac-radio-input>
        </div>
        <div class="file-input">
          <input
            formControlName="reorderNumber"
            type="text"
            name="prev-order-num"
            placeholder="Previous order number"
          />
        </div>
        <zmsac-form-control-validation-message class="validation-message" controlName="reorderNumber">
        </zmsac-form-control-validation-message>
      </zmsaw-radio-card>

    </div>
    <div [ngClass]="animPreview" class="field">
      <zmsaw-image-preview
        *ngIf="(uploadingStage$ | async) === UploadStage.Preview"
        [imageURL]="processedImage$ | async"
      >
      </zmsaw-image-preview>
    </div>
    <div class="field">
      <input
        formControlName="specialRequest"
        type="text"
        name="prev-order-num"
        placeholder="Special request / changes to your cutline"
      />
    </div>
    <div class="field btn-container">
      <button class="back-btn" type="button" (click)="onBackButtonClick()">
        Back
      </button>
      <button [disabled]="isSubmitUnavailable$ | async" class="add-to-cart-btn" type="submit">
        <span class="icon basket"></span>
        Add to cart
      </button>
    </div>
  </form>
</div>
