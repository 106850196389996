import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { controlProviderFor, ValueAccessor } from '@zmsac/common/core/utils/value-accessor';
import { ReplaySubject } from 'rxjs';

/**
 * Type guard for file values.
 * @param value Value.
 */
function isFile(value?: File | null | string): value is File {
  return (value as File).name !== undefined;
}

/**
 * Custom file input component.
 */
@Component({
  selector: 'zmsaw-custom-file-input',
  templateUrl: './custom-file-input.component.html',
  styleUrls: ['./custom-file-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(CustomFileInputComponent)],
})
export class CustomFileInputComponent extends ValueAccessor<File | undefined> {

  public readonly fileName$ = new ReplaySubject<string | null>(1);

  /** @inheritDoc */
  @HostListener('change', ['$event.target.files.item(0)'])
  public override emitChange(value?: File | null): void {
    if (isFile(value)) {
      super.emitChange(value);
    }
  }

  /** @inheritDoc */
  public override writeValue(value?: File | null): void {
    super.writeValue(value);
    if (value == null) {
      this.fileName$.next(null);
    }
  }

  /**
   * Handle file uploading.
   * @param event Event.
   */
  public onFileUpload(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.item(0);
    if (file == null) {
      this.fileName$.next(null);
    } else {
      this.fileName$.next(file.name);
    }
  }
}
