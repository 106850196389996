import { ALPHA_CHANNEL, PIXEL_STEP } from '@zmsac/common/constants';
import { Point } from '@zmsac/common/core/models/point';

/**
 * Generates stroke for image. Returns new canvas only with the stroke.
 * @param ctx Original image rendering context.
 * @param brush Brush canvas for drawing stroke.
 * @param contentPadding Image padding.
 */
export function generateStroke(
  ctx: CanvasRenderingContext2D,
  brush: HTMLCanvasElement,
  contentPadding: number,
): HTMLCanvasElement {
  const strokeCanvas = document.createElement('canvas');
  const strokeCtx = strokeCanvas?.getContext('2d') as CanvasRenderingContext2D;

  const { height } = ctx.canvas;
  const { width } = ctx.canvas;
  strokeCanvas.height = height;
  strokeCanvas.width = width;

  // Greedy edge detection. Checking each valuable pixel.
  // Some optimisation by skipping valuable pixels.

  // One dimensional array with RGBA image value.
  const { data } = ctx.getImageData(0, 0, width, height);

  for (
    let i = ALPHA_CHANNEL + width * PIXEL_STEP * contentPadding;
    i < data.length - width * PIXEL_STEP * contentPadding;
    i += PIXEL_STEP
  ) {
    const pixel = new Point(
      Math.floor(i / PIXEL_STEP) % width - contentPadding,
      Math.floor(Math.floor(i / PIXEL_STEP) / width) - contentPadding,
    );

    if (data[i]) {
      // Defining searching area.
      const searchArea = [
        i - width * PIXEL_STEP - PIXEL_STEP,
        i - width * PIXEL_STEP,
        i - width * PIXEL_STEP + PIXEL_STEP,
        i + PIXEL_STEP,
        i + width * PIXEL_STEP + PIXEL_STEP,
        i + width * PIXEL_STEP,
        i + width * PIXEL_STEP - PIXEL_STEP,
        i - PIXEL_STEP,
      ];

      // Searching for empty values. Draw circle if find any.
      let isBorder = false;
      for (let j = 0; j < searchArea.length; j++) {
        // eslint-disable-next-line max-depth
        if (!data[searchArea[j]]) {
          strokeCtx.drawImage(brush, pixel.x, pixel.y + 1);
          strokeCtx.drawImage(brush, pixel.x, pixel.y - 1);
          strokeCtx.drawImage(brush, pixel.x + 1, pixel.y);
          strokeCtx.drawImage(brush, pixel.x - 1, pixel.y);
          strokeCtx.drawImage(brush, pixel.x + 1, pixel.y + 1);
          strokeCtx.drawImage(brush, pixel.x - 1, pixel.y - 1);
          strokeCtx.drawImage(brush, pixel.x + 1, pixel.y - 1);
          strokeCtx.drawImage(brush, pixel.x - 1, pixel.y + 1);
          isBorder = true;
          break;
        }
      }

      // Skip valuable pixels.
      if (isBorder) {
        // eslint-disable-next-line max-depth
        while (
          data[i + PIXEL_STEP * 2] &&
          data[i + PIXEL_STEP + width * PIXEL_STEP] &&
          data[i + PIXEL_STEP - width * PIXEL_STEP]
        ) {
          i += PIXEL_STEP;
        }
      }
    }
  }

  return strokeCanvas;
}
