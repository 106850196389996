import { ALPHA_CHANNEL, PIXEL_STEP } from '@zmsac/common/constants';

/**
 * Trim empty spaces on all sides of image. Returns trimmed canvas.
 * @param canvas Original image canvas.
 */
export function trimToContent(
  canvas: HTMLCanvasElement,
): HTMLCanvasElement {
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  const data = ctx.getImageData(
    0,
    0,
    ctx.canvas.width,
    ctx.canvas.height,
  ).data as Uint8ClampedArray;
  const { width } = ctx.canvas;
  let [minX, minY, maxX, maxY] = [0, 0, 0, 0];

  // Loops for searching minmax values on X and Y of valuable pixels.

  for (let i = ALPHA_CHANNEL; i < data.length; i += PIXEL_STEP) {
    if (data[i]) {
      minY = Math.floor(i / (width * PIXEL_STEP));
      break;
    }
  }

  // Labeled loops used to break outer loop without "if" statements or hard math for optimizations purposes.
  // MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/label#using_a_labeled_break_with_for_loops.
  // eslint-disable-next-line no-labels
  searchMinXLoop:
  for (let x = 0; x < width; x++) {
    for (
      let i = ALPHA_CHANNEL + x * PIXEL_STEP;
      i < data.length;
      i += width * PIXEL_STEP
    ) {
      if (data[i]) {
        minX = x;
        // eslint-disable-next-line no-labels
        break searchMinXLoop;
      }
    }
  }

  for (let i = data.length - 1; i > 0; i -= PIXEL_STEP) {
    if (data[i]) {
      maxY = Math.floor(i / (width * PIXEL_STEP));
      break;
    }
  }

  // Labeled loops used to break outer loop without "if" statements or hard math for optimizations purposes.
  // MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/label#using_a_labeled_break_with_for_loops.
  // eslint-disable-next-line no-labels
  searchMaxXLoop:
  for (let x = width - 1; x > 0; x--) {
    for (
      let i = ALPHA_CHANNEL + x * PIXEL_STEP;
      i < data.length;
      i += width * PIXEL_STEP) {
      if (data[i]) {
        maxX = x;
        // eslint-disable-next-line no-labels
        break searchMaxXLoop;
      }
    }
  }

  const trimmedContent = document.createElement('canvas');
  trimmedContent.width = maxX - minX;
  trimmedContent.height = maxY - minY;
  const canvasContent = ctx.getImageData(
    minX,
    minY,
    maxX - minX,
    maxY - minY,
  ) as ImageData;
  trimmedContent.getContext('2d')?.putImageData(canvasContent, 0, 0);
  return trimmedContent;
}
