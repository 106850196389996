import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';

/* eslint-disable jsdoc/check-tag-names */
/* eslint-disable jsdoc/require-description-complete-sentence */
/**
 * Destroyable service.
 * [Taiga-UI implementation](https://github.com/Tinkoff/taiga-ui/blob/main/projects/cdk/services/destroy.service.ts).
 *
 * For components, it should be provided for each one in order to create individual instance of the service
 * for a component. Without it children components will be dependent on parent destroy service therefore they
 * still will be presented in memory and might cause memory leaks.
 *
 * In order to avoid such behavior we should use `Self` decorator to limit DI scopes for the component.
 *
 * Example of usage:
 * @Component({
 *   ...
 *   providers: [DestroyService],
 * })
 * export class ButtonComponent {
 *   public constructor(
 *    @Self() @Inject(DestroyService) private readonly destroy$: Observable<void>
 *   ) { }
 * }
 */
@Injectable()
export class DestroyService extends ReplaySubject<void> implements OnDestroy {

  public constructor() {
    super(1);
  }

  /** @inheritDoc */
  public ngOnDestroy(): void {
    this.next();
    this.complete();
  }
}
