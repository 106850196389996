/** Stroke types of image background. */
export enum StrokeType {

  /** Custom shape stroke. */
  Stroke = 'stroke',

  /** Circle. */
  Circle = 'circle',

  /** Ellipse. */
  Ellipse = 'ellipse',

  /** Rectangle. */
  Rectangle = 'rectangle',

  /** Rounded rectangle with border. */
  RoundedRectangle = 'rounded-rectangle',
}
