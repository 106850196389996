/** Step used for ImageData processing to get same channel value of the next pixel. */
export const PIXEL_STEP = 4;

/** Alpha channel initial pointer. */
export const ALPHA_CHANNEL = 3;

/** Default fade color. */
export const FADE_COLOR = 'rgba(255, 255, 255, 0)';

/** Default content padding in pixels. */
export const PADDING_SCALE = 0.025;
