export type RgbColorConstructor = Pick<RgbColor,
  | 'red'
  | 'green'
  | 'blue'
  | 'alpha'>;

/**
 * Color in RGB model.
 */
export class RgbColor {

  /** Default(white) RGB color. */
  public static readonly DEFAULT_COLOR = new RgbColor({ red: 255, blue: 255, green: 255, alpha: 1 });

  /** Red. */
  public readonly red: number;

  /** Green. */
  public readonly green: number;

  /** Blue. */
  public readonly blue: number;

  /** Alpha channel. */
  public readonly alpha: number;

  public constructor(data: RgbColorConstructor) {
    this.red = data.red;
    this.green = data.green;
    this.blue = data.blue;
    this.alpha = data.alpha;
  }

  /**
   * Create color in RBG model from HEX model.
   * Since HEX might be represented in 3 and in 6 characters we should consider both cases.
   * @param color Color in HEX model.
   */
  public static fromHex(color: string): RgbColor {
    let red = 0;
    let green = 0;
    let blue = 0;

    if (color.length === 4) {
      red = Number(`'0x'${color[1]}${color[1]}`);
      green = Number(`0x${color[2]}${color[2]}`);
      blue = Number(`0x${color[3]}${color[3]}`);

    } else if (color.length === 7) {
      red = Number(`0x${color[1]}${color[2]}`);
      green = Number(`0x${color[3]}${color[4]}`);
      blue = Number(`0x${color[5]}${color[6]}`);
    }

    return new RgbColor({ red, green, blue, alpha: 1 });
  }

  /** Return current RGB color in HEX model. */
  public toHex(): string {
    const r = Number(this.red).toString(16);
    const g = Number(this.green).toString(16);
    const b = Number(this.blue).toString(16);

    return `#${r}${g}${b}`;
  }

  /** Return string representation of color. */
  public toString(): string {
    return `rgba(${Math.trunc(this.red)}, ${Math.trunc(this.green)}, ${Math.trunc(this.blue)}, ${Math.trunc(this.alpha)})`;
  }
}
