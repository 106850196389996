import { Injectable } from '@angular/core';
import { Order, OrderCreationData } from '@zmsac/common/core/models/order';

/**
 * Order mapper.
 */
@Injectable({
  providedIn: 'root',
})
export class OrderMapper {

  /**
   * Map to order creation data.
   * @param order Order.
   * @param image Image.
   */
  public toOrderCreationData(order: Order, image: string | null): OrderCreationData {
    return {
      image,
      imageDeliveryType: order.imageDeliveryType,
      originalImage: order.originalImage,
      reorderNumber: order.reorderNumber,
      specialRequest: order.specialRequest,
    };
  }
}
