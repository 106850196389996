/** Thickness type of image background. */
export enum ThicknessType {

  /** Small (default) size. */
  Small = 1,

  /** Average size. */
  Medium = 2,

  /** Big size. */
  Large = 3,
}
