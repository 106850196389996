import { Injectable } from '@angular/core';
import { AppError } from '@zmsac/common/core/models/app-error';

/**
 * Service to work with files.
 */
@Injectable({
  providedIn: 'root',
})
export class FilesService {

  /**
   * Convert external image source to base 64 format.
   * For example:
   * https://external-source.com/jpeg -> data:image/jpg;base64....
   * @param url Url image source.
   */
  public async urlToBase64(url: string): Promise<string> {
    const externalImage = await fetch(url);
    const blobImage = await externalImage.blob();
    return this.blobToBase64(blobImage);
  }

  /**
   * Convert given url to blob format.
   * @param url Url.
   */
  public async urlToBlob(url: string): Promise<Blob> {
    const image = await fetch(url);
    return image.blob();
  }

  /**
   * Convert given array buffer into blob format.
   * @param buffer Buffer.
   */
  public arrayBufferToBase64(buffer: ArrayBuffer): Promise<string> {
    const blob = new Blob([buffer]);
    return this.blobToBase64(blob);
  }

  /**
   * Convert file into base64 format.
   * @param file File source.
   */
  public fileToBase64(file: File): Promise<string> {
    return this.blobToBase64(file);
  }

  /**
   * Convert file into array buffer.
   * @param file File.
   */
  public fileToArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const fileReader = new FileReader();

      // TODO: (Romanov) Avoid casting.
      fileReader.onloadend = () => resolve((fileReader?.result as ArrayBuffer));
      fileReader.onerror = () => reject(new AppError('Error in file reading process.'));
      fileReader.readAsArrayBuffer(file);
    });
  }

  /**
   * Convert blob source into base64 format.
   * @param blob Blob source.
   */
  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onloadend = () => resolve(fileReader.result?.toString() ?? '');
      fileReader.onerror = () => reject(new AppError('Error in file reading process.'));
      fileReader.readAsDataURL(blob);
    });
  }
}
