import { Injectable, ApplicationRef, Injector, ElementRef } from '@angular/core';
import { OrderMapper } from '@zmsac/common/core/mappers/order.mapper';
import { Order } from '@zmsac/common/core/models/order';

/**
 * Order service.
 */
@Injectable({
  providedIn: 'root',
})
export class OrdersService {

  /** Reference to root component of the app. */
  private readonly appElementRef: ElementRef;

  public constructor(
    applicationRef: ApplicationRef,
    injector: Injector,
    private readonly orderMapper: OrderMapper,
  ) {
    this.appElementRef = injector.get(applicationRef.componentTypes[0]).elementRef;
  }

  /**
   * Place order with provided value.
   * @param order Order data.
   * @param image Image.
   */
  public placeOrder(order: Order, image: string | null): void {
    const addEvent = new CustomEvent('zmsa-add', {
      detail: this.orderMapper.toOrderCreationData(order, image),
    });
    this.appElementRef.nativeElement.dispatchEvent(addEvent);
  }

  /**
   * Return to the start menu.
   */
  public backToMenu(): void {
    const backEvent = new CustomEvent('zmsa-back');
    this.appElementRef.nativeElement.dispatchEvent(backEvent);
  }
}
