import { RgbColor } from '@zmsac/common/core/models/rgb-color';

import { StrokeType } from './../enums/stroke-type';
import { ThicknessType } from './../enums/thickness';

type RedactorConfigurationConstructor = Partial<Omit<RedactorConfiguration, 'enrich'>>;

/**
 * Size of final sticker in relative units.
 * F.e. 3x4 or 10x9.
 */
export interface SizeBorder {

  /** Width. */
  readonly width: number;

  /** Height. */
  readonly height: number;
}

/** Processing options. */
export class RedactorConfiguration {

  /** Default background color(white). */
  public static readonly DEFAULT_COLOR = new RgbColor({ red: 255, green: 255, blue: 255, alpha: 255 });

  /** Default size border. */
  public static readonly DEFAULT_SIZE_BORDER = { width: 3, height: 3 };

  /** Color of the redactor background. */
  public readonly color: RgbColor;

  /** Thickness of stroke. */
  public readonly thickness: ThicknessType;

  /** Stroke type. */
  public readonly strokeType: StrokeType;

  /** Size border. */
  public readonly sizeBorder: SizeBorder;

  public constructor(data: RedactorConfigurationConstructor) {
    this.color = data.color ?? RedactorConfiguration.DEFAULT_COLOR;
    this.sizeBorder = data.sizeBorder ?? RedactorConfiguration.DEFAULT_SIZE_BORDER;
    this.thickness = data.thickness ?? ThicknessType.Small;
    this.strokeType = data.strokeType ?? StrokeType.Stroke;
  }

  /** @inheritDoc */
  public enrich(options: Partial<this>): RedactorConfiguration {
    return new RedactorConfiguration({
      color: options.color ?? this.color,
      thickness: options.thickness ?? this.thickness,
      strokeType: options.strokeType ?? this.strokeType,
      sizeBorder: {
        width: options.sizeBorder?.width ?? this.sizeBorder.width,
        height: options.sizeBorder?.height ?? this.sizeBorder.height,
      },
    });
  }
}
