import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RadioInputComponent } from '@zmsac/common/shared/components/radio-input/radio-input.component';
import { SpinnerComponent } from '@zmsac/common/shared/components/spinner/spinner.component';
import { SwitchInputComponent } from '@zmsac/common/shared/components/switch-input/switch-input.component';

import {
  FormControlValidationMessageComponent,
} from './components/form-control-validation-message/form-control-validation-message.component';
import { PopUpMessageComponent } from './components/pop-up-message/pop-up-message.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { AppValidationDirective } from './directives/app-validation.directive';

const EXPORTED_DECLARATIONS = [
  AppValidationDirective,
  FormControlValidationMessageComponent,
  RadioInputComponent,
  SpinnerComponent,
  SwitchInputComponent,
  PopUpMessageComponent,
];

/**
 * Common shared module.
 * Contains reusable components, directives etc can be shared through all apps in the project.
 */
@NgModule({
  declarations: [
    ValidationMessageComponent,
    ...EXPORTED_DECLARATIONS,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
  exports: [...EXPORTED_DECLARATIONS],
})
export class CommonSharedModule { }
