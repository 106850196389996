/**
 * Point class for working with pixels coordinates.
 */
export class Point {

  /**
   * @param x Position value on X axis.
   * @param y Position value on Y axis.
   */
  public constructor(
    public x: number,
    public y: number,
  ) { }

  /**
   * Returns distance between current and passed point.
   * @param point Point to calculate distance.
   */
  public distanceTo(point: Point): number {
    return Math.floor(
      Math.sqrt(
        (Math.abs(point.x - this.x ** 2)) + (Math.abs(point.y - this.y ** 2)),
      ),
    );
  }
}
