import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';

import { ProcessingProgress } from '../models/processing-progress';

/**
 * Processing progress service.
 */
@Injectable({
  providedIn: 'root',
})
export class ProcessingProgressService {

  /** Processing progress. */
  public readonly processingProgress$: Observable<ProcessingProgress | null>;

  private readonly _processingProgress$ = new BehaviorSubject<ProcessingProgress | null>(null);

  public constructor() {
    this.processingProgress$ = this._processingProgress$.asObservable().pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /**
   * Update current processing progress.
   * @param progress Progress.
   */
  public updateProgress(progress: ProcessingProgress | null): void {
    this._processingProgress$.next(progress);
  }

  /**
   * Reset current progress.
   */
  public resetTheProgress(): void {
    this._processingProgress$.next(null);
  }
}
