import { ALPHA_CHANNEL, PIXEL_STEP } from '@zmsac/common/constants';
import { ThicknessType } from '@zmsac/common/core/enums/thickness';
import { Point } from '@zmsac/common/core/models/point';

import { generateRectangleBrush } from './generate-square-brush';
import { generateStroke } from './generate-stroke';

/**
 * Creates ellipse stroke.
 * For radius uses max distance between image edge pixels and content bounding box center.
 * (TODO: We can improve result quality by using some algorithms to search minimum bounding circle for content).
 * @param canvas Original image canvas.
 * @param contentPadding Padding to result canvas.
 * @param color Stroke color.
 * @param thickness Current image thickness.
 * @param scale Scale of second radius.
 */
export function createEllipseStoke(
  canvas: HTMLCanvasElement,
  contentPadding: number,
  color: string,
  thickness: ThicknessType,
  scale = 2,
): HTMLCanvasElement {
  // Maybe we can optimise with edge detection with inset radius check and breaking;
  const borderCanvas = generateStroke(
    <CanvasRenderingContext2D>canvas.getContext('2d'),
    generateRectangleBrush(1, 1, color),
    0,
  );

  const borderCtx = borderCanvas.getContext('2d') as CanvasRenderingContext2D;
  const borderData = borderCtx.getImageData(
    0,
    0,
    borderCanvas.width,
    borderCanvas.height,
  ).data;
  const { width, height } = borderCanvas;

  const center = new Point(
    Math.floor(width / 2),
    Math.floor(height / 2),
  );

  let maxRadius = 0;

  // Searching for maximum distance from the center to edge point and saving that point
  for (let i = ALPHA_CHANNEL; i < borderData.length; i += PIXEL_STEP) {
    if (borderData[i]) {
      const point = new Point(
        Math.floor(i / PIXEL_STEP) % width,
        Math.floor(Math.floor(i / PIXEL_STEP) / width),
      );
      const ellipseRadius = Math.sqrt(
        (point.x - center.x) ** 2 /
        scale ** 2 + (point.y - center.y) ** 2,
      );

      if (ellipseRadius > maxRadius) {
        maxRadius = ellipseRadius;
      }
    }
  }

  /* Calculate radiuses */

  let horizontalRadius = maxRadius * scale;
  maxRadius += (contentPadding * thickness);
  horizontalRadius += (contentPadding * thickness);

  const ellipseCanvas = document.createElement('canvas');
  ellipseCanvas.width = horizontalRadius * 2;
  ellipseCanvas.height = maxRadius * 2;

  const ellipseCtx = ellipseCanvas.getContext('2d') as CanvasRenderingContext2D;
  ellipseCtx.beginPath();
  ellipseCtx.fillStyle = color;
  ellipseCtx.ellipse(
    horizontalRadius,
    maxRadius,
    horizontalRadius,
    maxRadius,
    0,
    0,
    Math.PI * 2,
  );
  ellipseCtx.fill();
  ellipseCtx.closePath();

  ellipseCanvas.getContext('2d')?.drawImage(
    canvas,
    Math.abs(ellipseCanvas.width / 2 - canvas.width / 2),
    Math.abs(ellipseCanvas.height / 2 - canvas.height / 2),
  );

  return ellipseCanvas;
}
