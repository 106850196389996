import { PIXEL_STEP } from '@zmsac/common/constants';
import { Point } from '@zmsac/common/core/models/point';

/** Directions of possible step. */
enum StepDirection {

  /** There is no direction. */
  NONE = 0,

  /** Step up. */
  UP = 1,

  /** Step left. */
  LEFT = 2,

  /** Step down. */
  DOWN = 3,

  /** Step right. */
  RIGHT = 4,
}

/**
 * This method using marching merchant algorithm.
 * @param originalCanvas Original background canvas with possible gaps.
 * @param color Color for filling gaps.
 */
export class MarchingSquaresOpt {

  private nextStep = StepDirection.NONE;

  /**
   * Return blob outline points.
   * @param originalCanvas Original canvas.
   */
  public getBlobOutlinePoints(
    originalCanvas: HTMLCanvasElement,
  ): Point[] {

    /** Search for first valuable pixel. */
    const startingPoint = this.getFirstNonTransparentPixelTopDown(originalCanvas);
    if (startingPoint === null) {
      return [];
    }

    /** Return array of width and height. */
    return this.walkPerimeter(startingPoint, originalCanvas);
  }

  /**
   * Return first non-transparent pixel.
   * @param canvas Original canvas.
   */
  private getFirstNonTransparentPixelTopDown(
    canvas: HTMLCanvasElement,
  ): Point | null {
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    // eslint-disable-next-line max-statements-per-line
    let y; let i; let rowData;
    for (y = 0; y < canvas.height; y++) {
      rowData = context.getImageData(0, y, canvas.width, 1).data;
      for (i = 0; i < rowData.length; i += PIXEL_STEP) {
        /** This will alway point on alpha channel of current pixel. */
        if (rowData[i + 3] > 0) {
          return new Point(i / 4, y);
        }
      }
    }
    return null;
  }

  /**
   * Function for walking on perimeter.
   * @param startingPoint Starting point.
   * @param sourceCanvas Source canvas.
   */
  private walkPerimeter(
    startingPoint: Point,
    sourceCanvas: HTMLCanvasElement,
  ): Point[] {

    const currentPoint = new Point(startingPoint.x, startingPoint.y);

    /** Return list. */
    const pointList = [];

    /** Main while loop, continues stepping until we return to our initial points. */
    do {

      this.makeStep(currentPoint, sourceCanvas);

      if (
        currentPoint.x >= 0 &&
        currentPoint.x < sourceCanvas.width &&
        currentPoint.y >= 0 &&
        currentPoint.y < sourceCanvas.height
      ) {
        pointList.push(new Point(currentPoint.x - 1, currentPoint.y - 1));
      }

      switch (this.nextStep) {
        case StepDirection.UP:
          currentPoint.y--;
          break;
        case StepDirection.LEFT:
          currentPoint.x--;
          break;
        case StepDirection.DOWN:
          currentPoint.y++;
          break;
        case StepDirection.RIGHT:
          currentPoint.x++;
          break;
        default:
          break;
      }

    } while (currentPoint.x !== startingPoint.x || currentPoint.y !== startingPoint.y);

    return pointList;
  }

  /**
   * Determines and sets the state of the 4 pixels that represent our current state.
   * Sets our current and previous direction.
   * @param point Current point.
   * @param canvas Current Canvas part.
   */
  private makeStep(point: Point, canvas: HTMLCanvasElement): number {

    const topLeftPoint = new Point(point.x - 1, point.y - 1);

    /** Scan given 4 pixel area. */
    const canvasCtx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const canvasImageData = canvasCtx.getImageData(topLeftPoint.x, topLeftPoint.y, 2, 2).data;

    /** Representation of 1 marching square. */
    const upLeft = new Pixel(new Point(topLeftPoint.x, topLeftPoint.y), canvasImageData[3] > 0, canvasImageData[3]);
    const upRight = new Pixel(new Point(topLeftPoint.x + 1, topLeftPoint.y), canvasImageData[7] > 0, canvasImageData[7]);
    const downLeft = new Pixel(new Point(topLeftPoint.x, topLeftPoint.y + 1), canvasImageData[11] > 0, canvasImageData[11]);
    const downRight = new Pixel(new Point(topLeftPoint.x + 1, topLeftPoint.y + 1), canvasImageData[15] > 0, canvasImageData[15]);

    /** These calculations will be handy for algorithm improvements. */
    // Const topMiddle = new Point(topLeftPoint.x + 0.5, topLeftPoint.y).
    // Const rightMiddle = new Point(topLeftPoint.x + 1, topLeftPoint.y + 0.5).
    // Const bottomMiddle = new Point(topLeftPoint.x + 0.5, topLeftPoint.y + 1).
    // Const leftMiddle = new Point(topLeftPoint.x, topLeftPoint.y + 0.5).

    const prevStep = this.nextStep;

    /** Determine which state we are in. */
    const state = this.calculateState([upLeft, upRight, downLeft, downRight]);

    /**
     * Now state is represented by number between 0 and 15.
     * In binary, it looks like 0000 - 1111.
     */

    /**
     * An example. Let's say the top two pixels are filled,
     * and the bottom two are empty.
     * Stepping through the if statements above with a state
     * of 0b0000 initially produces:
     * Upper Left == true ==>  0b0001
     * Upper Right == true ==> 0b0011
     * The others are false, so 0b0011 is our state
     * (That's 3 in decimal.).
     *
     * Looking at the chart above, we see that state
     * corresponds to a move right, so in our switch statement
     * below, we add a case for 3, and assign Right as the
     * direction of the next step. We repeat this process
     * for all 16 states.
     */

    switch (state) {
      case 1:
        this.nextStep = StepDirection.UP;
        break;
      case 2:
        this.nextStep = StepDirection.RIGHT;
        break;
      case 3:
        this.nextStep = StepDirection.RIGHT;
        break;
      case 4:
        this.nextStep = StepDirection.LEFT;
        break;
      case 5:
        this.nextStep = StepDirection.UP;
        break;
      case 6:
        if (prevStep === StepDirection.UP) {
          this.nextStep = StepDirection.LEFT;
        } else {
          this.nextStep = StepDirection.RIGHT;
        }
        break;
      case 7:
        this.nextStep = StepDirection.RIGHT;
        break;
      case 8:
        this.nextStep = StepDirection.DOWN;
        break;
      case 9:
        if (prevStep === StepDirection.RIGHT) {
          this.nextStep = StepDirection.UP;
        } else {
          this.nextStep = StepDirection.DOWN;
        }
        break;
      case 10:
        this.nextStep = StepDirection.DOWN;
        break;
      case 11:
        this.nextStep = StepDirection.DOWN;
        break;
      case 12:
        this.nextStep = StepDirection.LEFT;
        break;
      case 13:
        this.nextStep = StepDirection.UP;
        break;
      case 14:
        this.nextStep = StepDirection.LEFT;
        break;
      default:
        /** This should never happen. */
        this.nextStep = StepDirection.NONE;
        break;
    }
    return this.nextStep;
  }

  private calculateState(pixels: Pixel[]): number {

    let state = 0;

    if (pixels[0].isValuable) {
      state += 1;
    }
    if (pixels[1].isValuable) {
      state += 2;
    }
    if (pixels[2].isValuable) {
      state += 4;
    }
    if (pixels[3].isValuable) {
      state += 8;
    }

    return state;
  }
}

/**
 * Represent standalone pixel.
 */
class Pixel {

  public constructor(
    public readonly point: Point,
    public readonly isValuable: boolean,
    public readonly pixelValue: number,
  ) { }

}
