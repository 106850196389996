import { Injectable } from '@angular/core';
import { StrokeType } from '@zmsac/common/core/enums/stroke-type';
import { Mime } from '@zmsac/common/core/models/mime';
import { ProcessedImage } from '@zmsac/common/core/models/processed-image';
import { RgbColor } from '@zmsac/common/core/models/rgb-color';
import { FilesService } from '@zmsac/common/core/services/files.service';
import { ImageApiService } from '@zmsac/common/core/services/image-api.service.';
import { BehaviorSubject, firstValueFrom, Observable, of, ReplaySubject, tap } from 'rxjs';
import { debounceTime, delay, switchMap } from 'rxjs/operators';

import { strokeImage } from '../../../web/src/app/shared/utils/image-processing';
import { ThicknessType } from '../enums/thickness';
import { ProcessingProgress } from '../models/processing-progress';
import { RedactorConfiguration } from '../models/redactor-configuration';

import { ProcessingProgressService } from './processing-progress.service';

const PROCESSING_DELAY_MS = 1000;
const DEBOUNCE_NUMBER_MS = 500;

/**
 * Processing configuration.
 */
interface ProcessingConfiguration {

  /** File for processing operations. */
  readonly file: File;

  /** Whether background should be removed or not. */
  readonly shouldRemoveBackground: boolean;
}

/** Service for processing images. */
@Injectable({
  providedIn: 'root',
})
export class ImageProcessingService {

  /** Image in base64 format. */
  public readonly imageInBase64Format$: Observable<string | null>;

  /** Redactor options. */
  public readonly redactorOptions$: Observable<RedactorConfiguration>;

  private readonly _redactorOptions$ = new BehaviorSubject<RedactorConfiguration>(new RedactorConfiguration({}));

  private readonly processingConfiguration$ = new ReplaySubject<ProcessingConfiguration>(1);

  private readonly processNewOptions$ = new BehaviorSubject<void>(void 0);

  public constructor(
    private readonly processingProgressService: ProcessingProgressService,
    private readonly imageApiService: ImageApiService,
    private readonly filesService: FilesService,
  ) {
    this.redactorOptions$ = this._redactorOptions$.asObservable();
    this.imageInBase64Format$ = this.initBase64Image();
  }

  /**
   * Sets current file.
   * @param file Image to set.
   * @param shouldRemoveBackground Whether background should be removed or not.
   */
  public setCurrentImage(file: File, shouldRemoveBackground: boolean): void {
    const processingConfig: ProcessingConfiguration = { file, shouldRemoveBackground };
    this.processingConfiguration$.next(processingConfig);
  }

  /**
   * Change current processing options.
   * @param options Options.
   * @param withProcessing Should image be processed or not.
   */
  public changeProcessingOptions(options: Partial<RedactorConfiguration>, withProcessing = false): void {
    this._redactorOptions$.next(this._redactorOptions$.value.enrich(options));

    if (withProcessing) {
      this.processNewOptions$.next();
    }
  }

  /** Reset options that was selected in widget. */
  public resetPartOfOptions(): void {
    this._redactorOptions$.next(this._redactorOptions$.value.enrich({
      color: RgbColor.DEFAULT_COLOR,
      thickness: ThicknessType.Small,
    }));
  }

  /** Reset all processing options to default. */
  public resetAllOptions(): void {
    this._redactorOptions$.next(new RedactorConfiguration({}));
  }

  private initBase64Image(): Observable<string | null> {
    return this.processingConfiguration$.pipe(
      tap(() => {
        this.processingProgressService.updateProgress(ProcessingProgress.Preparing);
      }),
      switchMap(configuration => this.removeBackground(configuration)),
      tap(result => {
        if (result != null && this._redactorOptions$.value.strokeType === StrokeType.Stroke) {
          this._redactorOptions$.next(this._redactorOptions$.value.enrich({ color: result.backgroundColor }));
        }
      }),
    ).pipe(
      switchMap(result => {
        if (result == null) {
          return of(null);
        }
        return this.processNewOptions$.pipe(
          debounceTime(DEBOUNCE_NUMBER_MS),
          tap(() => this.processingProgressService.updateProgress(ProcessingProgress.Drawing)),
          switchMap(() => strokeImage(result.image, this._redactorOptions$.value)),
          delay(PROCESSING_DELAY_MS),
          tap(() => {
            this.processingProgressService.updateProgress(ProcessingProgress.Completed);
          }),
        );
      }),
    );
  }

  private async removeBackground({ shouldRemoveBackground, file }: ProcessingConfiguration): Promise<ProcessedImage | null> {
    try {
      if (!shouldRemoveBackground) {
        return {
          image: await this.filesService.fileToBase64(file),
          backgroundColor: new RgbColor(RgbColor.DEFAULT_COLOR),
        };
      }
      if (Mime.extractFrom(file) === Mime.SupportedType.PDF) {
        return firstValueFrom(this.imageApiService.removePdfBackground(file));
      }
      return firstValueFrom(this.imageApiService.removeImageBackground(file));
    } catch (error: unknown) {
      return null;
    }
  }
}
