import { ValidatorFn, AbstractControlTyped } from '@angular/forms';
import { Mime } from '@zmsac/common/core/models/mime';
import { ZMSAValidators } from '@zmsac/common/core/utils/validators';

export namespace FileValidation {

  /**
   * Validate mime image mime type.
   * @param mimeTypes Supported mime types.
   */
  export function validateImageMime(mimeTypes: readonly Mime.SupportedType[]): ValidatorFn {
    return (({ value: file }: AbstractControlTyped<File | string | null>) => {
      if (file == null || typeof file === 'string') {
        return null;
      }

      if (mimeTypes.some(mime => Mime.extractFrom(file).toLocaleLowerCase() === mime)) {
        return null;
      }

      return ZMSAValidators.buildAppError(`"${Mime.extractFrom(file)}" is invalid format. "${mimeTypes.join(', ')}" expected`);
    });
  }
}
