import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@zmsac/common/shared/common-shared.module';

/**
 * Shared module.
 */
@NgModule({
  declarations: [],
  imports: [
    CommonSharedModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  exports: [CommonSharedModule],
})
export class SharedModule { }
