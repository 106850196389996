/** Progress of image processing. */
export enum ProcessingProgress {
  Preparing = 'Preparing',
  Cutting = 'Cutting',
  Drawing = 'Drawing',
  Completed = 'Completed',
}

export namespace ProcessingProgress {
  const READABLE_MAP_OF_PROGRESS: Readonly<Record<ProcessingProgress, string>> = {
    [ProcessingProgress.Preparing]: 'Preparing the image',
    [ProcessingProgress.Cutting]: 'Cutting background',
    [ProcessingProgress.Drawing]: 'Drawing shape',
    [ProcessingProgress.Completed]: 'Completed',
  };

  /**
   * Return human-readable description of processing progress.
   * @param progress Processing progress.
   */
  export function toReadable(progress: ProcessingProgress): string {
    return READABLE_MAP_OF_PROGRESS[progress];
  }
}
