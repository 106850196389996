import { ThicknessType } from '@zmsac/common/core/enums/thickness';

import { MarchingSquaresOpt } from './fill-background-whitespaces';
import { trimToContent } from './trim-to-content';

/** Size type of dimensions. */
enum DimensionSizeTime {

  /** Small size. */
  Small = 300,

  /** Medium size. */
  Medium = 500,

  /** Large size. */
  Large = 1000,

  /** Extra-large size. */
  ExtraLarge = 5000,
}

/**
 * Scale size of given dimension.
 * @param size Size.
 */
function scaleDimensionSize(size: number): number {
  if (size < DimensionSizeTime.Small) {
    return size * 10;
  } else if (size < DimensionSizeTime.Medium) {
    return size * 5.5;
  } else if (size < DimensionSizeTime.Large) {
    return size * 3.5;
  } else if (size < DimensionSizeTime.ExtraLarge) {
    return size * 2;
  }
  return size * 1.3;

}

/**
 * Creates shape stroke around image. Returns new canvas.
 * @param originalCanvas Original image canvas.
 * @param contentPadding Weight of the stroke.
 * @param color Stroke color.
 * @param thickness Thickness of background.
 */
// eslint-disable-next-line max-lines-per-function
export function createShapeStroke(
  originalCanvas: HTMLCanvasElement,
  contentPadding: number,
  color: string,
  thickness: ThicknessType,
): HTMLCanvasElement {

  const IMAGE_SHIFT = 320;

  const temporaryCanvas = document.createElement('canvas');

  /**
   * Resize canvas so that most of images wll fit in it with shadow.
   */
  const imageHolder = document.createElement('canvas');
  const imageHolderCtx = imageHolder.getContext('2d') as CanvasRenderingContext2D;
  imageHolder.width = originalCanvas.width;
  imageHolder.height = originalCanvas.height;

  imageHolderCtx.drawImage(originalCanvas, 0, 0);

  /**
   * If an image will be small we should create more space for it, so it can fit with it's shadow and IMAGE SHIFT to the frame.
   */
  originalCanvas.width = scaleDimensionSize(originalCanvas.width);
  originalCanvas.height = scaleDimensionSize(originalCanvas.height);

  const originalCanvasCtx = originalCanvas.getContext('2d') as CanvasRenderingContext2D;
  originalCanvasCtx.drawImage(imageHolder, IMAGE_SHIFT, IMAGE_SHIFT);
  imageHolder.remove();

  temporaryCanvas.width = originalCanvas.width;
  temporaryCanvas.height = originalCanvas.height;

  const imageData = originalCanvasCtx.getImageData(0, 0, originalCanvas.width, originalCanvas.height);
  const numberOfPixels = imageData.data.length;
  const renderingContext = temporaryCanvas.getContext('2d') as CanvasRenderingContext2D;

  /**
   * We are using shadow as border, so we need to just apply it to the image.
   */
  renderingContext.save();
  renderingContext.shadowColor = color;
  renderingContext.globalAlpha = 1;
  renderingContext.shadowBlur = contentPadding * thickness;
  renderingContext.drawImage(originalCanvas, IMAGE_SHIFT, IMAGE_SHIFT);
  renderingContext.restore();

  /**
   * Dive into image data of original image + shadow.
   * Remove transparency from shadow.
   */
  const tempImageData = renderingContext.getImageData(IMAGE_SHIFT, IMAGE_SHIFT, temporaryCanvas.width, temporaryCanvas.height);

  const SMOOTH_MIN_THRESHOLD = 15;
  const SMOOTH_MAX_THRESHOLD = 20;

  let val;

  for (let i = 3; i < numberOfPixels; i += 4) {
    /** Skip opaque pixels. */
    if (imageData.data[i] === 255) {
      continue;
    }

    val = tempImageData.data[i];
    if (val === 0) {
      continue;
    }
    if (val > SMOOTH_MAX_THRESHOLD) {
      val = 255;
    } else if (val < SMOOTH_MIN_THRESHOLD) {
      val = 0;
    } else {
      val =
        ((val - SMOOTH_MIN_THRESHOLD) /
          (SMOOTH_MAX_THRESHOLD - SMOOTH_MIN_THRESHOLD)) *
        255;
    }
    tempImageData.data[i] = val;
  }

  /** Draw resulted image (original + shadow without opacity) into canvas. */
  renderingContext.putImageData(tempImageData, IMAGE_SHIFT, IMAGE_SHIFT);

  /** Fill whole background with color (after that shadow is colored). */
  renderingContext.save();
  renderingContext.globalCompositeOperation = 'source-in';
  renderingContext.fillStyle = color;
  renderingContext.fillRect(0, 0, originalCanvas.width, originalCanvas.height);
  renderingContext.restore();

  /** Apply marching squares algorithm to the canvas without image for creating outline. */
  const marchingSquaresOpt = new MarchingSquaresOpt();
  const pathPoints = marchingSquaresOpt.getBlobOutlinePoints(temporaryCanvas);

  /**
   * If algorithm above find object on image outline will be drawn.
   * Then all object filling with color.
   * That will remove all gaps and make image background plain.
   */
  renderingContext.beginPath();
  renderingContext.lineWidth = 2;
  renderingContext.strokeStyle = 'rgba(255, 255, 255, 0)';
  renderingContext.moveTo(pathPoints[0].x, pathPoints[0].y);

  for (let i = 1; i < pathPoints.length; i++) {
    const point = pathPoints[i];
    renderingContext.lineTo(point.x, point.y);
  }

  renderingContext.fillStyle = color;
  renderingContext.closePath();
  renderingContext.stroke();
  renderingContext.fill();

  /**
   * Calculate shifted x and y position for original image.
   */
  const scaledXPosition = Math.ceil((temporaryCanvas.width - originalCanvas.width) / 2);
  const scaledYPosition = Math.ceil((temporaryCanvas.height - originalCanvas.height) / 2);
  renderingContext.drawImage(
    originalCanvas,
    0,
    0,
    temporaryCanvas.width,
    temporaryCanvas.height,
    scaledXPosition + IMAGE_SHIFT,
    scaledYPosition + IMAGE_SHIFT,
    temporaryCanvas.width,
    temporaryCanvas.height,
  );

  /** Remove whitespaces and return trimmed image. */
  return trimToContent(temporaryCanvas);
}
