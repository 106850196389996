import { Injectable } from '@angular/core';

/**
 * App config service.
 */
@Injectable({
  providedIn: 'root',
})
export class AppConfigService {

  /** Url for all static assets / images. */
  public readonly bucketUrl = $ENV.S3_URL;

  /** Processing url. */
  public readonly processingUrl = $ENV.PROCESSING_URL;
}
