import { enumToArray } from '@zmsac/common/core/utils/enum-to-array';

export namespace Mime {

  /** Supported mime types. */
  export enum SupportedType {
    PDF = 'pdf',
    JPEG = 'jpeg',
    JPG = 'jpg',
    PNG = 'png',
    BPM = 'bpm',
    GIF = 'gif',
    TIFF = 'tiff',
  }

  /**
   * Whether provided mime type is supported or not.
   * @param type Mime type.
   */
  export function isSupportedType(type: Mime.SupportedType | string): type is Mime.SupportedType {
    return enumToArray(Mime.SupportedType).includes(type as Mime.SupportedType);
  }

  /**
   * Extract mime type from given file.
   * @param file File.
   */
  export function extractFrom(file: File): string {
    return file.name.split('.').splice(-1)[0];
  }
}
