import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  Self,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormControlTyped } from '@angular/forms';
import { ThicknessType } from '@zmsac/common/core/enums/thickness';
import { RgbColor } from '@zmsac/common/core/models/rgb-color';
import { AppConfigService } from '@zmsac/common/core/services/app-config.service';
import { DestroyService } from '@zmsac/common/core/services/destroy.service';
import { ImageProcessingService } from '@zmsac/common/core/services/image-processing.service';
import { fabric } from 'fabric';
import { BehaviorSubject, merge, Observable, switchMap, map } from 'rxjs';
import { mapTo, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { assertNonNull, assertNonNullablePropertiesWithReturn } from '@zmsac/common/core/utils/assert-non-null';
import { ProcessingProgress } from '@zmsac/common/core/models/processing-progress';
import { ProcessingProgressService } from '@zmsac/common/core/services/processing-progress.service';

import { drawSizeBorder } from '../../../shared/utils/image-processing/methods/draw-size-border';

/**
 * Get fabric image form provided source.
 * @param source Image source.
 */
function getFabricImage(source: string): Promise<fabric.Image> {
  return new Promise<fabric.Image>(resolve => {
    fabric.Image.fromURL(source, image => resolve(image), {
      selectable: false,
      hasControls: false,
      hoverCursor: 'default',
      moveCursor: 'default',
    });
  });
}

const DEFAULT_CANVAS_SIZE = 500;

/**
 * Image preview component.
 */
@Component({
  selector: 'zmsaw-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: [
    './image-preview.component.css',
    '../image-upload.module.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ImagePreviewComponent implements OnInit, AfterViewInit, OnDestroy {

  /** Preview image URL. */
  @Input()
  public set imageURL(url: string | null) {
    if (url !== null) {
      this.imageUrlValue$.next(url);
    }
  }

  /** Canvas element. */
  @ViewChild('canvas', { read: ElementRef })
  public canvasElement!: ElementRef;

  /** Section that contain canvas element. */
  @ViewChild('canvasSection', { read: ElementRef })
  public canvasSectionElement!: ElementRef;

  /** Url of the image for preview. */
  public readonly imageUrl$: Observable<string>;

  /** Processing progress. */
  public readonly processingProgress$: Observable<ProcessingProgress | null>;

  /** Processing progress. */
  public readonly ProcessingProgress = ProcessingProgress;

  /** Thickness type. */
  public readonly ThicknessType = ThicknessType;

  /** Control for color picker. */
  public readonly colorControl: FormControlTyped<string>;

  /** Path redactor background. */
  public readonly backgroundPath: string;

  private readonly imageUrlValue$ = new BehaviorSubject<string>('');

  private resizeObserver: ResizeObserver | null = null;

  private canvas: fabric.Canvas | null = null;

  public constructor(
    @Self() @Inject(DestroyService) private readonly destroy$: Observable<void>,
    public readonly processingService: ImageProcessingService,
    public readonly appConfigService: AppConfigService,
    processingProgressService: ProcessingProgressService,
    formBuilder: FormBuilder,
  ) {
    this.processingProgress$ = processingProgressService.processingProgress$;
    this.colorControl = formBuilder.controlTyped<string>('');
    this.imageUrl$ = this.imageUrlValue$.asObservable();
    this.backgroundPath = `${this.appConfigService.bucketUrl}/assets/background.svg`;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    merge(
      this.setProcessingOptionsSideEffect(),
    ).pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  /** @inheritDoc */
  public ngAfterViewInit(): void {
    this.canvas = this.initializeCanvas();
    this.resizeObserver = this.initializeResizeObserver();
    this.resizeObserver.observe(this.canvasSectionElement.nativeElement);

    merge(
      this.addImageToCanvasSideEffect(),
    ).pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  /** @inheritDoc */
  public ngOnDestroy(): void {
    if (this.resizeObserver != null) {
      this.resizeObserver.disconnect();
    }
  }

  /**
   * Change thickness of image background.
   * @param thicknessRate Thickness of background.
   */
  public onBackgroundThicknessChange(thicknessRate: ThicknessType): void {
    this.processingService.changeProcessingOptions({ thickness: thicknessRate }, true);
  }

  /**
   * Change color of image background.
   * @param event Event.
   */
  public onBackgroundColorChange(event: Event): void {
    const newBackgroundColor = (event.target as HTMLInputElement).value;
    this.processingService.changeProcessingOptions({ color: RgbColor.fromHex(newBackgroundColor) }, true);
  }

  private setProcessingOptionsSideEffect(): Observable<void> {
    return this.processingService.redactorOptions$.pipe(
      tap(({ color }) => this.colorControl.setValue(color.toHex())),
      mapTo(void 0),
    );
  }

  private addImageToCanvasSideEffect(): Observable<void> {
    return this.imageUrlValue$.pipe(
      switchMap(source => getFabricImage(source)),
      withLatestFrom(this.processingService.redactorOptions$),

      /** Since we need a size box only for preview mode. It shouldn't modify the image itself. */
      map(([image, { sizeBorder }]) => drawSizeBorder(image, sizeBorder)),
      tap(image => {
        assertNonNull(this.canvas);
        const { height, width } = assertNonNullablePropertiesWithReturn(this.canvas, 'height', 'width');

        if (height <= width) {
          image.scaleToWidth(this.canvas.getWidth());
        } else {
          image.scaleToHeight(this.canvas.getHeight());
        }
        this.canvas.clear();
        this.canvas.add(image);
        this.canvas.centerObject(image);
      }),
      mapTo(void 0),
    );
  }

  private initializeResizeObserver(): ResizeObserver {
    return new ResizeObserver(entries => {
      entries.forEach(entry => {
        const { contentRect } = entry;
        this.resizeCanvas(contentRect);
      });
    });
  }

  private initializeCanvas(): fabric.Canvas {
    return new fabric.Canvas(this.canvasElement.nativeElement, {
      height: DEFAULT_CANVAS_SIZE,
      width: DEFAULT_CANVAS_SIZE,
      selection: false,
    });
  }

  private resizeCanvas(contentRect: DOMRectReadOnly): void {
    assertNonNull(this.canvas);
    const widthScale = contentRect.width / this.canvas.getWidth();
    const heightScale = contentRect.height / this.canvas.getHeight();
    const canvasObjects = this.canvas.getObjects();

    canvasObjects.forEach(currentObject => {
      currentObject.scaleX = (currentObject.scaleX ?? 1) * widthScale;
      currentObject.scaleY = (currentObject.scaleY ?? 1) * heightScale;
      currentObject.setCoords();
    });

    this.canvas.discardActiveObject();

    if (widthScale > 1) {
      this.canvas.setHeight(this.canvas.getHeight() * heightScale);
    } else {
      this.canvas.setWidth(this.canvas.getWidth() * widthScale);
    }

    this.canvas.requestRenderAll();
  }
}
