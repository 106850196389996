import { Injectable } from '@angular/core';
import { RgbColorDto, STROKE_COLOR_DTO } from '@zmsac/common/core/mappers/dto/rgb-color.dto';
import { RgbColor } from '@zmsac/common/core/models/rgb-color';

import { ProcessedImage } from '../models/processed-image';

import { ProcessedImageDto } from './dto/processed-image-dto';
import { IMapperFromDto } from './mappers';

/**
 * Mapper for processed image.
 */
@Injectable({
  providedIn: 'root',
})
export class ProcessImageMapper implements IMapperFromDto<ProcessedImageDto, ProcessedImage> {

  /** @inheritdoc */
  public fromDto(dto: ProcessedImageDto): ProcessedImage {
    return {
      image: dto.output_image,
      backgroundColor: this.fromRgbColorDto(dto.background_color ?? STROKE_COLOR_DTO),
    };
  }

  private fromRgbColorDto(dto: RgbColorDto): RgbColor {
    return new RgbColor({
      red: dto[0],
      green: dto[1],
      blue: dto[2],
      alpha: dto[3],
    });
  }
}
