import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Radio-card element component. */
@Component({
  selector: 'zmsaw-radio-card',
  templateUrl: './radio-card.component.html',
  styleUrls: [
    './radio-card.component.css',
    '../image-upload.module.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioCardComponent { }
